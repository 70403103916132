<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="items.items"
      item-key="id"
      class="elevation-0 background-transparent"
      :items-per-page="25"
      :loading="loading"
      :options.sync="options"
      :server-items-length="items.meta.totalItems"
      :footer-props="{
        'items-per-page-options': rowsPerPageItemsGlobal
      }"
    >
      <template v-slot:top>
        <v-row no-gutters class="pr-1 justify-space-between" style="width: 100%;">
          <h1 class="text-h4 mt-2">{{ $lang.routes.storage }}</h1>
          <div class="my-2" style="display: flex; align-items: center">
            <v-autocomplete
              v-model="processId"
              outlined
              dense
              :items="processes"
              :loading="isLoadingProcesses"
              :search-input.sync="searchProcesses"
              hide-no-data
              class="pl-1"
              clearable
              item-text="name"
              item-value="id"
              hide-details
              :label="$lang.labels.process"
              :placeholder="$lang.actions.startTyping"
              prepend-inner-icon="mdi-cloud-search-outline"
              style="max-width: 600px"
            >
              <template slot="selection" slot-scope="data">
                {{ data.item.name }}
              </template>
            </v-autocomplete>
            <v-text-field
              v-model="key"
              outlined
              clearable
              dense
              hide-details
              class="pl-2"
              :label="$lang.labels.key"
              data-cy="search-by-key"
            ></v-text-field>
            <v-btn
              text
              light
              class="ml-2"
              color="primary"
              @click="sendQuery()"
            >
              <v-icon
                dense
                small
              >
                mdi-refresh
              </v-icon>
            </v-btn>
            <v-btn
              color="accent"
              class="color-accent-text ml-2"
              :to="{ name: 'storageCreate', params: { lang: $lang.current_lang } }"
            >{{ $lang.actions.create }}</v-btn>
          </div>

        </v-row>
        <v-col cols="12" class="px-1 mt-2 alert-message">
          <v-alert dark color="secondary" :data-cy="$lang.hints.storageHint">{{ $lang.hints.storageHint }}</v-alert>
        </v-col>
        <v-divider class="mt-5"></v-divider>
      </template>

      <template v-slot:item.id="{ item }">
        <div class="font-weight-bold"># {{ item.id }}</div>
      </template>

      <template v-slot:item.createdOn="{ item }">
        <div>{{ item.createdOn | formatDateTime }}</div>
      </template>

      <template v-slot:item.modifiedOn="{ item }">
        <div>{{ item.modifiedOn | formatDateTime }}</div>
      </template>

      <template v-slot:item.expireOn="{ item }">
        <div>{{ item.expireOn | formatDateTime }}</div>
      </template>

      <template v-slot:item.processName="{ item }">
        <div>
          {{ item.processName }}
          <v-btn
            text
            small
            dark
            color="primary"
            class="ml-1"
            @click="openProcessInNewTabById(item.processId)"
          >{{ $lang.labels.open }}</v-btn>
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="d-inline-flex">
          <v-btn color="primary" class="mx-0" :to="{ name: 'storageEdit', params: { id: item.id } }">{{ $lang.actions.edit }}</v-btn>
        </div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { bus } from '@/main'
import { getProcessesUsingGET as getProcesses, getProcessByIdUsingGET as getProcess } from '@/utils/api'
export default {
  props: {
    items: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      lock: true,
      search: '',
      options: {},
      totalItems: 0,
      headers: [
        {
          text: this.$lang.labels.process,
          align: 'start',
          sortable: true,
          value: 'processName'
        },
        {
          text: this.$lang.labels.key,
          align: 'start',
          sortable: true,
          value: 'key'
        },
        {
          text: this.$lang.labels.value,
          align: 'start',
          sortable: false,
          value: 'value'
        },
        {
          text: this.$lang.labels.expireOn,
          align: 'start',
          sortable: true,
          value: 'expireOn'
        },
        { text: this.$lang.labels.actions, value: 'actions', align: 'end', sortable: false }
      ],
      roles: [],
      processes: [],
      fetchedProcesses: [],
      searchProcesses: '',
      isLoadingProcesses: false,
      processId: '',
      finishInit: false,
      key: ''
    }
  },
  computed: {
    ...mapState('app', ['rowsPerPageItemsGlobal', 'userRoles', 'userSettings'])
  },
  watch: {
    options: {
      handler () {
        if (!this.lock) this.sendQuery(false)
        this.savePreFill()
      },
      deep: true
    },
    searchProcesses: {
      handler(val) {
        if (val && val.length > 1) {
          this.searchProcessesFunction(val)
        }
      }
    },
    processId: {
      handler () {
        if (!this.lock) this.sendQuery()
      }
    },
    key: {
      handler () {
        if (!this.lock) this.sendQuery()
      }
    }
  },
  mounted() {
    bus.$on('refreshData', (name) => {
      if (!this.lock && name === this.$route.name) this.sendQuery()
    })
  },
  created() {
    this.roles = this.userRoles.map((x) => x.name) || []

    if (this.userSettings.display.showModifiedOn) {
      this.headers.splice(4, 0, { text: this.$lang.labels.modifiedOn, value: 'modifiedOn', sortable: true })
    }

    if (this.userSettings.display.showCreatedOn) {
      this.headers.splice(4, 0, { text: this.$lang.labels.createdOn, value: 'createdOn', sortable: true })
    }

    if (this.userSettings.display.showId) {
      this.headers.splice(0, 0, { text: this.$lang.header.id, value: 'id', sortable: true })
    }

    if (localStorage.preFillData) {
      const preFill = JSON.parse(localStorage.preFillData)

      if (preFill && preFill.storageList) {
        this.key = preFill.storageList.key
        this.processId = preFill.storageList.processId
        this.options = preFill.storageList.options

        if (this.processId) {
          getProcess({ id: this.processId })
            .then((res) => {
              this.searchProcesses = res.data.data.name
            })
            .catch((err) => {
              console.log(err)
            })
        }
      }
    }
    setTimeout(() => {
      this.lock = false
      this.sendQuery()
    }, 100)
  },
  methods: {
    ...mapActions('app', ['addSnackbar']),

    openProcessInNewTabById(id) {
      window.open(
        `/${localStorage.selectedLanguage || 'en'}/processes/edit/${id}`,
        '_blank'
      )
    },
    savePreFill() {
      if (!localStorage.preFillData) localStorage.preFillData = '{}'
      if (localStorage.preFillData) {
        const preFill = JSON.parse(localStorage.preFillData)

        if (!preFill.storageList) preFill.storageList = {
          processId: '',
          key: '',
          options: {
            page: 1,
            itemsPerPage: 25,
            sortBy: [],
            sortDesc: []
          }
        }

        preFill.storageList.processId = this.processId
        preFill.storageList.key = this.key
        preFill.storageList.options = { ...this.options, page: 1 }

        localStorage.preFillData = JSON.stringify(preFill)
      }
    },
    async sendQuery(resetPage = true) {
      if (this.lock) return
      const obj = { options: this.options, key: this.key, processId: this.processId }

      obj.resetPage = resetPage

      this.$emit('fetchStorages', obj)
      this.savePreFill()
    },
    searchProcessesFunction(val = '') {
      if (this.lock) return
      this.isLoadingProcesses = true
      getProcesses({
        name: val || ''
      })
        .then((res) => {
          this.processes = res.data.data.items
          this.isLoadingProcesses = false
        })
        .catch((err) => {
          this.isLoadingProcesses = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })
        })
    }
  }
}
</script>
